@import '../base/variables';
@import '../base/mixins';

.dashboard__filters {
  text-align: center;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 18px;
  grid-row-gap: 28px;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.br-tl-0 {
  border-top-left-radius: 0 !important;
}

.dashboard__filter {
  padding: 23px;
  border: 1px solid var(--ink-1);
  border-radius: $border-radius-base;

  .filter__icon {
    padding-bottom: 11px;
  }
  .filter__number {
    font-family: var(--font-family-alt);
    font-size: em(32);
    font-weight: 400;
    line-height: 38px;
    padding-bottom: 10px;
  }
  .filter__label {
    font-family: var(--font-family-alt);
    font-size: em(18);
    font-weight: 400;
    line-height: 21px;
  }
}

